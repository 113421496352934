.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  font-size: var(--font-size-small);
  margin-left: 8px;
  margin-bottom: 4px;
}

.inputContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--secondary);
  border: 1px solid var(--semi-light-blue);
  border-radius: 8px;
  padding: 8px;
  gap: 8px;
}

.input {
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
  width: 100%;
  font-size: var(--font-size-main);
}

.input:focus-visible {
  outline: none;
}

.inputs {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.separator {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.errorContainer {
  border: 1px solid var(--dark-red);
}