.chargingPoint {
  border-radius: 8px;
}

.energy {
  display: flex;
  gap: 2px;
  margin: 16px 0;
}

.energyDetails {
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 16px;
  width: 45%;
  border-radius: 8px 0 0 8px;
}

.data {
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-size: var(--font-size-small);
}

.header {
  font-size: var(--font-size-header);
}

.connector {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  border-radius: 0 8px 8px 0;
  font-size: 10px;
}

.durationSection {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
}

.durationRow {
  display: flex;
  gap: 16px;
  font-size: var(--font-size-small);
}

.times {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 32px;
  font-size: var(--font-size-small);
}

.timesRow {
  display: flex;
  justify-content: space-between;
}

.connectorType {
  font-size: 10px;
  font-weight: var(--font-weight-bold);
  line-height: 12.1px;
}

.url {
  text-decoration: none;
}

.receiptLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-small);
}

.icon {
  width: 24px;
}
