.container {
    margin: 0px 16px;
    padding-bottom: 24px;
}

.chartContainer {
    overflow-x: auto;
    overflow-y: hidden;
    margin: 0 -16px;
    padding: 0 10px;
    min-width: 0;
    width: 100%;
}

.tariff {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    gap: 10px;
    margin: 10px 0;
}

.tariffCol {
    border-radius: 8px;
    margin-bottom: 10px;
}

.description {
    color: #666;
    font-size: 12px;
    line-height: 1.5;
    margin: 16px 0;
}

.section {
    margin: 24px 0;
}

.sectionTitle {
    font-size: 18px;
    font-weight: 600;
}

.fixedTariffGrid {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 12px;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 8px;
}

.fixedTariffLabel {
    color: #444;
    font-size: 12px;
}

.fixedTariffValue {
    font-weight: 500;
    font-size: 12px;
    font-weight: bold;
    text-align: right;
}

.legendTitle {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 2px;
}

.legendWrapper {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 10px;
    margin-top: 10;
}

.legendContainer {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
}

.legendItem {
    display: flex;
    align-items: center;
    gap: 3px;
    font-size: 12px;
    color: #444;
}

.legendDot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.legendNote {
    color: #666;
    font-size: 10px;
    margin-top: 5px;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 16px;
    background-color: #f5f5f5;
    border-radius: 8px;
    overflow: hidden;
}

.tableCell {
    font-size: 10px;
    white-space: nowrap;
}

.table th,
.table td {
    padding: 12px;
    text-align: left;
    font-size: 10px;
    border-bottom: 1px solid #e0e0e0;
}

.table th {
    background-color: #f0f0f0;
}

.table tr:last-child td {
    border-bottom: none;
}

/* Tooltip styles */
.tooltipContainer {
    background-color: white !important;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    min-width: 150px;
    font-size: 12px;
    opacity: 1 !important;
}

.tooltipTime {
    font-weight: 600;
    margin-bottom: 8px;
    padding-bottom: 5px;
    border-bottom: 1px solid #eee;
    text-align: center;
}

.tooltipItem {
    display: flex;
    justify-content: space-between;
    margin: 4px 0;
}

.legendCell {
    font-size: 10px;
    font-weight: bold; 
}