.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.icon {
    padding: 0 16px 16px 16px;
}

.code {
    align-self: center;
    font-size: var(--font-size-header);
    font-weight: var(--font-weight-semi-bold);
    padding: 8px 0;
}

.description {
    font-size: var(--font-size-main);
    padding-bottom: 24px;
}