.container {
  display: flex;
  flex-direction: row;
  gap: 2px;
  cursor: pointer;
}

.label {
  color: var(--dark-blue);
}

.icon {
  margin-top: 1px;
  margin-left: 10px;
}
