.container {
  padding-bottom: 32px;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.details {
  display: flex;
  justify-content: space-between;
}

.tariff {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap: 10px;
  margin: 16px 0;
}

.dayNightTariff {
  background-color: var(--secondary);
  border-radius: 8px;
}

.bold {
  font-weight: var(--font-weight-bold);
}

.tariffRow {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-small);
}

.outOfOrderMessage {
  color: var(--dark-red);
  font-size: var(--font-size-small);
}

.tariffCol {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: var(--secondary);
  justify-content: space-between;
  gap: 0px;
}

.unusedDeposit {
  display: flex;
  text-align: center;
  font-size: var(--font-size-small);
  border-radius: 6px;
}

.tariffAction {
  margin: 16px 0;
}

.terms {
  display: flex;
  align-items: center;
  gap: 8px; 
  font-size: 14px;
}

.termsInput {
  width: 21px;
  height: 24px;
  cursor: pointer;
}

.termsDescription {
  cursor: pointer;
}

.termsUrl {
  color: var(--extra-dark-blue);
  font-weight: var(--font-weight-bold);
}
