.container {
  margin: 16px 0;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
}

.detailsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2px;
  margin-bottom: 2px;
}

.addressSection {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
  margin-bottom: 2px;
  gap: 16px;
}

.locationName {
  font-size: var(--font-size-header);
  font-weight: var(--font-weight-semi-bold);
  line-height: 26.63px;
}

.preKwhRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1px;
  margin-bottom: 2px;
}

.details {
  display: flex;
  justify-self: center;
  align-items: center;
  padding: 12px;
  gap: 16px;
}

.detailsCol {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data {
  display: flex;
  flex-direction: column;
}

.energy {
  font-size: var(--font-size-header);
  line-height: 26.63px;
}

.id {
  font-size: var(--font-size-small);
  line-height: 15.73px;
}

.connectorContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex: 1;
  padding: 12px;
}

.connector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.connectorType {
  font-size: 10px;
  font-weight: var(--font-weight-bold);
  line-height: 12.1px;
}

.tariffRow {
  display: flex;
  flex:1;
  flex-direction: row;
  align-items: center;
  margin-top: 2px;
  padding: 10px
}

.tariffData {
  width: 100%;
  margin-left: 16px;
}

.action {
  width: 30%;
  margin-left: auto;
}

.evseAction {
  padding: 6px 32px;
}
