.line {
    border: 1px solid var(--grey);
    margin: 16px 0px;
}

.bottom_navigation_links__flex-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
}

.bottom_navigation_links__flex-item{
    color: var(--black);
    text-decoration: none;
    font-weight: bolder;
    font-size: 13px;
    font-family: var(--font) sans-serif;
    line-height: 15px;
    align-items:center;
    justify-content: center;
    display: inline-flex;
    gap: 10px;
    text-align: center;
}