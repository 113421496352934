@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

:root {
  /* Default values for colors set from tenant/instance configuration through setCssColorVariables method */
  --primary: #1e6cce;
  --primary-gradient: #36a4e1;
  --secondary: #eff5fc;

  /* colors */
  --black: #000000;
  --white: #ffffff;
  
  --semi-light-blue:#DDE9F8;
  --light-blue: #c1daff;  
  --semi-dark-blue: #2f83ff;
  
  --dark-blue: #0E60C3;
  --extra-dark-blue: #0029ff;
  --light-green: #bde4bf;
  --medium-green: #7DC35F;
  --dark-green: #24a629;
  --light-red: #ffd2d2;
  --dark-red: #ff6868;
  --light-grey: #e7e7e7;
  --grey: #c7daf3;
  --light-orange: #FFE8C5;
  --dark-orange: #FFB23E;
  /* font */
  --font: "Inter", sans-serif;

  /* font-size */
  --font-size-small: 13px;
  --font-size-main: 16px;
  --font-size-header: 22px;

  /* font-weight */
  --font-weight-thin: 100;
  --font-weight-extra-light: 200;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;
  --font-weight-black: 800;
}

* {
  margin: 0;
  padding: 0;
  font-family: var(--font);
}

html {
  height: 100%;
}

body {
  font-family: var(--font);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-main);
  height: 100%;
  margin: 0 auto;
}

.page-container {
  padding: 0 16px 16px 16px;
}

.blue-card {
  background-color: var(--secondary);
}

.separator {
  border: 1px solid var(--white);
}

.bold {
  font-weight: var(--font-weight-bold);
}

.fa-circle-check {
  color: var(--dark-green);
}

.fa-circle-xmark {
  color: var(--dark-red);
}

.fa-circle-info {
  color: var(--semi-dark-blue);
}

.fa-location-dot {
  color: var(--black);
}

.general-error {
  color: var(--dark-red);
  font-size: var(--font-size-small);
  margin: 8px 0;
}
