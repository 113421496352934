.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  gap: 8px;
  font-size: var(--font-size-small);
  display: flex;
  margin: 0 auto;
  z-index: 1;
}

.bottom {
  position: fixed;
  left: 16px;
  right: 16px;
  bottom: 16px;
}

.unset {
  position: unset;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.headerMain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerText {
  margin-left: 8px;
  line-height: 15.73px;
}

.body {
  text-align: left;
}

.close {
  cursor: pointer;
}

.success {
  border: 1px solid var(--dark-green);
  background-color: var(--light-green);
}

.error {
  border: 1px solid var(--dark-red);
  background-color: var(--light-red);
}

.info {
  border: 1px solid var(--semi-dark-blue);
  background-color: var(--light-blue);
}

.warning {
  border: 1px solid var(--dark-orange);
  background-color: var(--light-orange);
}
