.chargingPoint {
  border-radius: 8px;
  margin-bottom: 16px;
}

.mainContent {
  margin-bottom: 32px;
}

.charging {
  display: flex;
  padding: 12px;
  gap: 8px;
  border-radius: 8px 8px 0 0;
}

.battery {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: var(--font-size-small);
  padding: 8px;
  border-radius: 0 0 8px 8px;
}

.detailsRow {
  display: flex;
  justify-content: space-between;
}

.error {
  display: flex;
  color: var(--dark-red);
  font-size: var(--font-size-small);
  padding: 8px 8px 0;
}