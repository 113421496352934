.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1;
  height: 63px;
  border-radius: 5px;
  background-color: var(--black);
  color: var(--white);
  margin-top: 16px;
  text-decoration: none;
}

.label {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.labelBold {
  font-size: 18px;
  font-weight: var(--font-weight-bold);
}
