.container {
  padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;
}

.details {
  display: flex;
  flex-direction: column;
}

.name {
  font-size: var(--font-size-header);
  font-weight: var(--font-weight-semi-bold);
  line-height: 26.63px;
}

.location {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  line-height: 15.73px;
}
