.container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
	gap: 48px;
  width: 100%;
  height: 100%;
  padding: 16px 0 32px 0;
}

.header {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	font-size: var(--font-size-header);
	font-weight: var(--font-weight-semi-bold);
}