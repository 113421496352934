.container {
  padding: 16px 12px;
   justify-content: space-between
}

.bottomBorder {
  border-radius: 0px 0px 8px 8px;
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  margin-left: 16px;
  line-height: 19.36px;
  font-weight: var(--font-weight-regular);
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.bold {
  font-weight: var(--font-weight-bold);
  line-height: 19.36px;
  text-align: center;
}

.red {
  color: var(--dark-red)
}

.soc {
  display: flex;
}
 
.socIcon {
  width: 40px;
  height: 20px;
  align-self: center;
}