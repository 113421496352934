.icons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin: 16px 0;
}

.keyboardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  margin: 2rem auto;
  max-width: 22.5rem;
}

.digit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.625rem;
  height: 5.625rem;
  border-radius: 0.5rem;
  font-size: 3rem;
  font-weight: var(--font-weight-semi-bold);
  line-height: 3.631rem;
  background-color: var(--secondary);
  cursor: pointer;
}

.placeholder {
  opacity: 0;
  cursor: auto;
}

.backspace {
  width: 35px;
}

.disabled {
  pointer-events: none;
  cursor: auto;
}
