.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 200px;
  margin: 0 16px;
  background-color: var(--white);
  border-radius: 8px;
  padding: 16px;
  max-width: 460px;
}
