.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 16px 32px;
  border: 0;
  border-radius: 28px;
  color: var(--white);
  cursor: pointer;
}

.label {
  font-size: var(--font-size-main);
  font-weight: var(--font-weight-bold);
  line-height: 19.36px;
}

.primary {
  background-image: linear-gradient(to right, var(--primary-gradient) 0%, var(--primary) 100%);
}

.secondary {
  color: var(--black);
  background-color: var(--white);
  border: 2px solid var(--primary);
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .button {
    padding: 14px 24px;
  }
}

@media (max-width: 480px) {
  .button {
    padding: 10px 16px;
  }

  .label {
    font-size: calc(var(--font-size-main) - 2px);
  }
}
