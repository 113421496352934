.container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 16px 0 32px;
}

.cardExpirationContainer {
  width: 90%;
}
