.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-header);
  font-weight: var(--font-weight-semi-bold);
  line-height: 26.63px;
  color: var(--black);
  height: 12vh;
}

.center {
  margin-left: 64px;
}

.button {
  text-align: center;
  padding: 4px 6px;
  border-radius: 8px;
  margin-right: 16px;
  cursor: pointer;
}

.call {
  margin-right: 0;
}
