.container {
display: flex;
flex-direction: column;
}

.logos {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.bold {
  font-weight: var(--font-weight-bold);
  line-height: 19.36px;
  text-align: center;
}