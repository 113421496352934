.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px;
}

.label {
  padding-left: 8px;
}
