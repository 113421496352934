.line {
    border: 1px solid var(--grey);
    margin: 16px 0px;
}

.receipt {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.link {
    color: var(--extra-dark-blue);
    font-weight: var(--font-weight-bold);
    line-height: 20px;
}