.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 86vh;
}

.section {
  margin-bottom: 32px;
}

.header {
  font-size: var(--font-size-header);
}

.description {
  font-size: var(--font-size-small);
  padding: 10px 0px;
}

.main,
.mainDescription {
  background-color: var(--secondary);
  padding: 10px 6px;
}

.main {
  border-radius: 8px 8px 0 0;
  margin-bottom: 2px;
}

.mainDescription {
  border-radius: 0 0 8px 8px;
}

.buttonContainer {
  margin-bottom: 100px;
}
